import { useMutation, useQuery } from "@apollo/client"
import Button from "@material-ui/core/Button"
import Divider from "@material-ui/core/Divider"
import Accordion from "@material-ui/core/Accordion"
import AccordionDetails from "@material-ui/core/AccordionDetails"
import AccordionSummary from "@material-ui/core/AccordionSummary"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import FormGroup from "@material-ui/core/FormGroup"
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles"
import Switch from "@material-ui/core/Switch"
import TextField from "@material-ui/core/TextField"
import Typography from "@material-ui/core/Typography"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import { DateTime } from "luxon"
import React from "react"
import { useForm } from "react-hook-form"
import { RouteChildrenProps } from "react-router"
import { AdminSQLQuery, AdminSQLQueryVariables } from "../../../__generated__/AdminSQLQuery"
import { adminUpsertGameInstance, adminUpsertGameInstanceVariables } from "../../../__generated__/adminUpsertGameInstance"
import { oneDay } from "../../../common/misc-utils"
import { fontWeight } from "../../utils/style-utils"
import Layout from "../components/Layout"
import LoadingView from "../components/LoadingView"
import { ADMIN_SQL_QUERY, ADMIN_UPSERT_GAME_INSTANCE_MUTATION } from "../queries"

const inputLabelProps = {
  shrink: true,
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    hiddenInput: {
      display: "none",
    },
    expansionArea: {
      display: "block",
    },
    periodTitle: {
      fontWeight: fontWeight.semiBold,
      marginTop: 10,
    },
  }),
)

function PeriodForm({ period, enableManualModeCallback, manualModeEnabled, inputRef }) {
  const [state, setState] = React.useState({
    checkedIsPickable: !!period.isPickable as boolean,
  })

  const handleChange = (name: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setState({ ...state, [name]: event.target.checked })
    if (name === "checkedIsPickable" && !!event.target.checked && !!!manualModeEnabled) {
      enableManualModeCallback()
    }
  }

  // if manual mode is turned off, we want to set the state back to the original period values
  if (!!!manualModeEnabled && !!state.checkedIsPickable != !!period.isPickable) {
    setState({ ...state, checkedIsPickable: !!period.isPickable })
  }

  const startsAtDate = new Date(period.startsAt)
  const locksAtDate = new Date(period.locksAt)

  const classes = useStyles()

  return (
    <>
      <Typography title={`${period.description}`} className={classes.periodTitle}>
        {`${period.description}`}
      </Typography>
      <Typography
        title={`Starts At - ${DateTime.fromMillis(startsAtDate.getTime()).toFormat("EEE L/dd/yy h:mma")}`}
      >{`Starts At - ${DateTime.fromMillis(startsAtDate.getTime()).toFormat("EEE L/dd/yy h:mma")}`}</Typography>
      <Typography title={`Locks At - ${DateTime.fromMillis(locksAtDate.getTime()).toFormat("EEE L/dd h:mma")}`}>{`Locks At - ${DateTime.fromMillis(
        locksAtDate.getTime(),
      ).toFormat("EEE L/dd h:mma")}`}</Typography>
      <FormGroup row={true}>
        <FormControlLabel
          control={
            <Switch
              checked={!!state.checkedIsPickable}
              onChange={handleChange("checkedIsPickable")}
              value={state.checkedIsPickable}
              name={`isPickable-${period.id}`}
              color="primary"
              inputRef={inputRef}
            />
          }
          label="Is Pickable"
        />
      </FormGroup>
      <Divider light={true} />
    </>
  )
}

// const toMilli = ()

function SeasonForm({ season, mutate, refetch, isSaving, periods }) {
  const classes = useStyles()
  const { register, handleSubmit } = useForm()
  const onSubmit = async (data) => {
    const { extraForSegment, inManualMode, seasonId, segmentId, simulateGameTimeOffset } = data

    const extraForSegmentJSON = extraForSegment ? JSON.parse(extraForSegment) : {}

    if (simulateGameTimeOffset) {
      extraForSegmentJSON.simulateGameTimeOffset = parseInt(simulateGameTimeOffset) * oneDay
    }

    const isInManualMode = inManualMode ? 1 : 0

    const segmentData = {
      extra: JSON.stringify(extraForSegmentJSON),
    }

    const seasonData = {
      isInManualMode,
    }

    const periodUpdates: any[] = []

    for (const key of Object.keys(data)) {
      if (key.match("isPickable-")) {
        const periodIsPickable = data[key]
        const periodData = {
          isInManualMode,
          isPickable: periodIsPickable ? 1 : 0,
        }
        const periodId = key.replace("isPickable-", "")
        const periodDataClone = Object.assign({}, periodData, { id: parseInt(periodId) })
        periodUpdates.push(periodDataClone)
      }
    }

    await mutate({
      variables: {
        mapping: {
          Segment: Object.assign({}, segmentData, {
            id: parseInt(segmentId),
            associations: {
              Period: periodUpdates,
            },
          }),
          Season: Object.assign({}, seasonData, { id: parseInt(seasonId) }),
        },
      },
    })
    // refetch original query
    await refetch()
  }

  const [state, setState] = React.useState({
    checkedIsManualMode: !!season.isInManualMode as boolean,
  })

  const handleChange = (name: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setState({ ...state, [name]: event.target.checked })
  }

  const enableManualMode = () => {
    setState({ ...state, checkedIsManualMode: true })
  }

  const { extra } = season

  const panelId = season.id
  const now = new Date()
  const currentPeriod = periods.filter((period) => period.isCurrent)
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={`${panelId}-content`} id={`${panelId}-header`}>
          <Typography
            title={`${season.description} - ${season.year} ${season.season} Season`}
          >{`${season.description} - ${season.year} ${season.season} Season - ${season.productAbbrev} (MPID: ${season.masterProductId})`}</Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.expansionArea}>
          <FormGroup row={true}>
            <FormControlLabel
              control={
                <Switch
                  checked={!!state.checkedIsManualMode}
                  onChange={handleChange("checkedIsManualMode")}
                  value={state.checkedIsManualMode}
                  name={"inManualMode"}
                  color="primary"
                  inputRef={register}
                />
              }
              label="Is In Manual Mode"
            />
          </FormGroup>
          {season.uid.match(/cbs-ncaab-confbracket-/)
            ? periods.map((period) => {
                return (
                  <PeriodForm
                    key={period.id}
                    period={period}
                    enableManualModeCallback={enableManualMode}
                    manualModeEnabled={!!state.checkedIsManualMode}
                    inputRef={register}
                  />
                )
              })
            : currentPeriod &&
              currentPeriod.length > 0 && (
                <PeriodForm
                  key={currentPeriod[0].id}
                  period={currentPeriod[0]}
                  enableManualModeCallback={enableManualMode}
                  manualModeEnabled={!!state.checkedIsManualMode}
                  inputRef={register}
                />
              )}
          <TextField
            id="simulateGameTimeOffset"
            label="Game Time Day Offset"
            type="number"
            defaultValue={parseInt(extra?.simulateGameTimeOffset || "0") / oneDay}
            className={undefined}
            InputLabelProps={inputLabelProps}
            inputRef={register}
            margin="normal"
            name={"simulateGameTimeOffset"}
          />
          <TextField
            id="extraForSegment"
            label="Extra"
            defaultValue={extra ? JSON.stringify(extra) : ""}
            className={classes.hiddenInput}
            fullWidth={true}
            InputLabelProps={inputLabelProps}
            inputRef={register}
            margin="normal"
            name={"extraForSegment"}
          />
          <TextField
            id="segmentId"
            label="Segment ID"
            defaultValue={season.segmentId}
            className={classes.hiddenInput}
            fullWidth={true}
            InputLabelProps={inputLabelProps}
            inputRef={register}
            margin="normal"
            name={"segmentId"}
          />
          <TextField
            id="seasonId"
            label="Season ID"
            defaultValue={season.seasonId}
            className={classes.hiddenInput}
            fullWidth={true}
            InputLabelProps={inputLabelProps}
            inputRef={register}
            margin="normal"
            name={"seasonId"}
          />
          <TextField
            id="date"
            label="Datebump Date"
            type="date"
            defaultValue={DateTime.fromMillis(now.getTime()).toFormat("yyyy-MM-dd")}
            className={undefined}
            fullWidth={true}
            InputLabelProps={inputLabelProps}
            inputRef={register}
            margin="normal"
            name={"datebumpDate"}
            disabled={true}
          />
          <TextField
            id="time"
            label="Datebump Time"
            type="time"
            defaultValue={DateTime.fromMillis(now.getTime()).toFormat("HH:mm:ss")}
            className={undefined}
            fullWidth={true}
            InputLabelProps={inputLabelProps}
            inputRef={register}
            margin="normal"
            name={"datebumpTime"}
            disabled={true}
          />
          <Button variant="contained" color="primary" disabled={isSaving} type="submit">
            {isSaving ? `Saving...` : `Save`}
          </Button>
        </AccordionDetails>
      </Accordion>
      <br />
    </form>
  )
}

function DateBump({ location, history }: RouteChildrenProps) {
  const response = useQuery<AdminSQLQuery, AdminSQLQueryVariables>(ADMIN_SQL_QUERY, {
    variables: {
      statement: `SELECT *, Segment.id AS segmentId FROM Segment LEFT JOIN Season ON Season.id = Segment.seasonId
      LEFT JOIN GameInstance ON GameInstance.id = Season.gameInstanceId where isCurrent = true
      ORDER BY Segment.id DESC`,
    },
  })
  const periodsQuery = useQuery<AdminSQLQuery, AdminSQLQueryVariables>(ADMIN_SQL_QUERY, {
    variables: {
      statement: `SELECT * from Period`,
    },
  })
  const [mutate, mutationResult] = useMutation<adminUpsertGameInstance, adminUpsertGameInstanceVariables>(ADMIN_UPSERT_GAME_INSTANCE_MUTATION)
  const seasons = response.data?.sql
  const periods = periodsQuery.data?.sql
  return (
    <Layout title={`Sim - Date Bump`}>
      <Typography variant="h4" component="h2">
        Game Instance
      </Typography>
      <br />
      {(seasons &&
        periods &&
        seasons.map((season) => {
          const periodsForSeason = periods.filter((period) => period.segmentId === season.segmentId)
          return (
            <SeasonForm
              season={season}
              mutate={mutate}
              refetch={response.refetch}
              isSaving={mutationResult.loading}
              key={season.id}
              periods={periodsForSeason}
            />
          )
        })) || <LoadingView />}
    </Layout>
  )
}

export default DateBump
